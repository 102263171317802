import { getDebugger } from '../libs/debug'
const gtmd = getDebugger('GTM', '#FFFFFF', '#1a73e8')
export const fireToGtm = (event: string, client_id?: string, params?: Record<string, any>) => {
  //   window.dataLayer = window.dataLayer || []
  if (window.dataLayer === undefined) {
    window.dataLayer = []
  }
  window.dataLayer.push({
    event,
    client_id,
    ...params,
  })

  gtmd('Event fired:', event, 'with client_id:', client_id, 'and params:', params)
}
