import { getDebugger } from '../libs/debug'
import { TinyEmitter } from 'tiny-emitter'
import Bowser from 'bowser'
import * as Sentry from '@sentry/browser'

Sentry.init({
  dsn: 'https://5cd3685903cb6f2b7c0a1979497d8e11@apm.banxso.com/2',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  //
  profilesSampleRate: 0.1,
})

const debug = getDebugger('BNXSO')

type SubscribableEvent =
  | 'depositSuccess'
  | 'depositFail'
  | 'signupSuccess'
  | 'signupFail'
  | 'loginSuccess'
  | 'loginFail'
  | 'logout'
  | 'init'
  | 'themeChange'
  | 'platformHide'
  | 'platformShow'
  | 'forex:depositSuccess'
  | 'forex:depositFail'
  | 'forex:signupSuccess'
  | 'forex:signupFail'
  | 'forex:loginSuccess'
  | 'forex:loginFail'
  | 'forex:logout'
  | 'forex:init'
  | 'forex:themeChange'
  | 'forex:platformHide'
  | 'forex:platformShow'

const versionDebugger =
  TradingPlatformVersion === 'dev'
    ? getDebugger('Version', '#000000', '#B71C1C')
    : TradingPlatformVersion === 'master'
      ? getDebugger('Version', '#000000', '#FF9800')
      : getDebugger('Version')

versionDebugger(TradingPlatformVersion === 'master' ? 'staging' : TradingPlatformVersion)

const knownPlatformEvents = [
  'depositSuccess',
  'depositFail',
  'signupSuccess',
  'signupFail',
  'loginSuccess',
  'loginFail',
  'logout',
  'init',
  'themeChange',
  'platformHide',
  'platformShow',
]

export class BnxsMain {
  readonly #bus: TinyEmitter
  #initialized: boolean
  constructor() {
    this.#initialized = false
    this.#bus = new TinyEmitter()
    debug('Initializing Forex Event Listeners')
    knownPlatformEvents.forEach((event) => {
      const eventKey = `forex:${event}`
      window.addEventListener(eventKey, (e) =>
        this.#onForexEvent(event, ...(e as CustomEvent).detail)
      )
    })
    debug('Initialized Forex Event Listeners:', knownPlatformEvents)
    const event = new CustomEvent('bnxs:loaded', { detail: this })
    window.dispatchEvent(event)
    if (Array.isArray(window.unconsumed)) {
      window.unconsumed.forEach((e) => {
        debug('Unconsumed Event:', e)
      })
    }
    this.#initialized = true
  }

  #onForexEvent(event: string, ...args: any[]) {
    debug('Got Forex Event:', event, ...args)
    this.#bus.emit(event, ...args)
    this.#bus.emit(`forex:${event}`, ...args)
  }

  public get initialized() {
    return this.#initialized
  }

  public on(event: SubscribableEvent, callback: (...args: any[]) => void) {
    this.#bus.on(event, callback)
  }

  public once(event: SubscribableEvent, callback: (...args: any[]) => void) {
    this.#bus.once(event, callback)
  }

  public off(event: SubscribableEvent, callback: (...args: any[]) => void) {
    this.#bus.off(event, callback)
  }

  public static mobileDetectAndRedirect() {
    debug('Checking if redirection to Mobile Trading Platform is needed')
    const urlParams = new URLSearchParams(window.location.search)
    const urlQueryString = '?' + urlParams.toString()
    debug({ urlQueryString })
    const browser = Bowser.getParser(window.navigator.userAgent)
    const parsedResult = browser.getResult()
    if (parsedResult.platform.type === 'mobile') {
      debug('The visitor is using a mobile browser.')
      window.isMobile = true
      window.isPluginMobile = true
      window.location.replace('https://' + window.mobileEnv + '.' + 'banxso.com' + urlQueryString)
      debug('https://' + window.mobileEnv + '.' + 'banxso.com' + urlQueryString)
      return true
    } else {
      debug('The visitor is not using a mobile browser.')
      window.isMobile = false
      window.isPluginMobile = false
      return false
    }
  }

  public static init() {
    const redirecting = BnxsMain.mobileDetectAndRedirect()
    if (redirecting) {
      return
    }
    return new BnxsMain()
  }
}

const bnxsMain = BnxsMain.init()
window.bnxsMain = bnxsMain
